import { Content, Page, Header, InfoCard } from '@backstage/core-components';
import {
  HomePageToolkit,
  HomePageStarredEntities,
  HomePageTopVisited,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { Grid } from '@material-ui/core';
import React from 'react';
import {
  NewAnnouncementBanner,
  AnnouncementsTimeline,
} from '@backstage-community/plugin-announcements';
import { tools } from './shared';

export const HomePage = () => {
  return (
    <Page themeId="home">
      <Header title="Portail développeur" />
      <Content>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item md={12}>
            <NewAnnouncementBanner />
          </Grid>
          <Grid container item xs={6} xl={6}>
            <Grid item md={12}>
              <InfoCard>
                <AnnouncementsTimeline />
              </InfoCard>
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item md={6}>
              <HomePageTopVisited />
            </Grid>
            <Grid item md={6}>
              <HomePageToolkit tools={tools} />
            </Grid>
            <Grid item xs={6}>
              <HomePageRecentlyVisited />
            </Grid>
            <Grid item xs={6}>
              <HomePageStarredEntities />
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
