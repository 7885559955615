import React, { ReactNode, useMemo, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
  isResourceType,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  Entity,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ReportIssue,
  TextSize,
  LightBox,
} from '@backstage/plugin-techdocs-module-addons-contrib';

// Plugins integrations
import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';
import {
  GitlabPipelineList,
  isGitlabAvailable,
  isGitlabJobsAvailable,
  GitlabJobs,
} from '@veecode-platform/backstage-plugin-gitlab-pipelines';
import { EntityKubernetesContent } from '@backstage/plugin-kubernetes';
import {
  EntityPrometheusContent,
  EntityPrometheusAlertCard,
  EntityPrometheusGraphCard,
  isPrometheusAvailable,
} from '@roadiehq/backstage-plugin-prometheus';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import {
  EntityFluxGitRepositoriesCard,
  EntityFluxHelmReleasesCard,
  EntityFluxOCIRepositoriesCard,
  EntityFluxKustomizationsCard,
  EntityFluxHelmRepositoriesCard,
  EntityFluxDeploymentsCard,
  EntityFluxSourcesCard,
  EntityFluxImagePoliciesCard,
} from '@weaveworksoss/backstage-plugin-flux';
import {
  EntityLighthouseContent,
  EntityLastLighthouseAuditCard,
  isLighthouseAvailable,
} from '@backstage-community/plugin-lighthouse';
// import { EntityFossaCard } from '@backstage/plugin-fossa';
import {
  EntityLinguistCard,
  isLinguistAvailable,
} from '@backstage-community/plugin-linguist';
import {
  EntityEndOfLifeCard,
  isEndOfLifeAvailable,
} from '@dweber019/backstage-plugin-endoflife';
import { EntityBadgesDialog } from '@backstage-community/plugin-badges';
import BadgeIcon from '@material-ui/icons/CallToAction';
import {
  EntityAmazonEcsServicesContent,
  isAmazonEcsServiceAvailable,
} from '@alithya-oss/backstage-plugin-amazon-ecs';
import {
  EntityAwsCodePipelineExecutionsContent,
  isAwsCodePipelineAvailable,
  EntityAwsCodePipelineCard,
} from '@alithya-oss/backstage-plugin-aws-codepipeline';
import {
  isAwsCodeBuildAvailable,
  EntityAwsCodeBuildCard,
} from '@alithya-oss/backstage-plugin-aws-codebuild';
import { EntityCostInsightsContent } from '@backstage-community/plugin-cost-insights';
import { isKubernetesAvailable } from '@backstage/plugin-kubernetes';

import {
  EntityChangelogContent,
  isChangelogAvailable,
} from '@alithya-oss/backstage-plugin-changelog';

import {
  EntityMaturityScorecardContent,
  EntityMaturitySummaryContent,
} from '@backstage-community/plugin-tech-insights-maturity';

import {
  AwsEnvironmentPage,
  AwsEnvironmentProviderPage,
  AwsComponentPage,
} from '@alithya-oss/backstage-plugin-aws-apps';

const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

  const extraMenuItems = useMemo(() => {
    return [
      {
        title: 'Badges',
        Icon: BadgeIcon,
        onClick: () => setBadgesDialogOpen(true),
      },
    ];
  }, []);

  return (
    <>
      <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
        {props.children}
      </EntityLayout>
      <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
    </>
  );
};

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
      <Mermaid
        config={{ theme: 'material', themeVariables: { lineColor: '#000000' } }}
      />
      <TextSize />
      <LightBox />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const isCICDContentAvailable = (entity: Entity) => {
  return (
    isGitlabAvailable(entity) ||
    isAwsCodePipelineAvailable(entity) ||
    isGithubActionsAvailable(entity)
  );
};

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GithubActions
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isCICDContentAvailable}>
        <EntitySwitch>
          <EntitySwitch.Case if={isGitlabAvailable}>
            <GitlabPipelineList />
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={isAwsCodePipelineAvailable}>
            <EntityAwsCodePipelineExecutionsContent />
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={isGithubActionsAvailable}>
            <EntityGithubActionsContent />
          </EntitySwitch.Case>
        </EntitySwitch>
      </EntitySwitch.Case>

      <EntitySwitch.Case>
        <EmptyState
          title="No CI/CD available for this entity"
          missing="info"
          description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
          action={
            <Button
              variant="contained"
              color="primary"
              href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
            >
              Read more
            </Button>
          }
        />
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}

    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>

    <EntitySwitch>
      <EntitySwitch.Case if={isAwsCodeBuildAvailable}>
        <Grid item md={6}>
          <EntityAwsCodeBuildCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isAwsCodeBuildAvailable}>
        <Grid item md={6}>
          <EntityAwsCodePipelineCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isLinguistAvailable}>
        <Grid item md={6}>
          <EntityLinguistCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isPrometheusAvailable}>
        <Grid item md={6}>
          <EntityPrometheusAlertCard />
        </Grid>
        <Grid item md={6}>
          <EntityPrometheusGraphCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isEndOfLifeAvailable}>
        <Grid item md={6}>
          <EntityEndOfLifeCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isLighthouseAvailable}>
        <Grid item md={6}>
          <EntityLastLighthouseAuditCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <Grid item md={6} xs={12}>
      <EntityFluxHelmReleasesCard />
    </Grid>

    <EntitySwitch>
      <EntitySwitch.Case if={isGitlabJobsAvailable}>
        <Grid item lg={6} xs={12}>
          <GitlabJobs />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <Grid item md={4} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={8} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/changelog"
      title="Changelog"
      if={isChangelogAvailable}
    >
      <EntityChangelogContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/costs" title="Cost Insights">
      <EntityCostInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/maturity" title="Maturity">
      <EntityMaturityScorecardContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/ecs"
      title="Amazon ECS"
      if={isAmazonEcsServiceAvailable}
    >
      <EntityAmazonEcsServicesContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/kubernetes"
      title="Kubernetes"
      if={isKubernetesAvailable}
    >
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>

    <EntityLayout.Route path="/prometheus" title="Prometheus">
      <EntityPrometheusContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/weaveworks-flux" title="Weave Flux">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12}>
          <EntityFluxHelmReleasesCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxKustomizationsCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxHelmRepositoriesCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxGitRepositoriesCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxOCIRepositoriesCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxDeploymentsCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxSourcesCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxImagePoliciesCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const websiteEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/changelog"
      title="Changelog"
      if={isChangelogAvailable}
    >
      <EntityChangelogContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/costs" title="Cost Insights">
      <EntityCostInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/maturity" title="Maturity">
      <EntityMaturityScorecardContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/lighthouse" title="Lighthouse">
      <EntityLighthouseContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/ecs"
      title="Amazon ECS"
      if={isAmazonEcsServiceAvailable}
    >
      <EntityAmazonEcsServicesContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/kubernetes"
      title="Kubernetes"
      if={isKubernetesAvailable}
    >
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>

    <EntityLayout.Route path="/prometheus" title="Prometheus">
      <EntityPrometheusContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/weaveworks-flux" title="Weave Flux">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12}>
          <EntityFluxHelmReleasesCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxKustomizationsCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxHelmRepositoriesCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxGitRepositoriesCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxOCIRepositoriesCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxDeploymentsCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxSourcesCard />
        </Grid>
        <Grid item md={12}>
          <EntityFluxImagePoliciesCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/costs" title="Cost Insights">
      <EntityCostInsightsContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('aws-app')}>
      <AwsComponentPage componentType="aws-app" />
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/costs" title="Cost Insights">
      <EntityCostInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/maturity" title="Maturity">
      <EntityMaturitySummaryContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/costs" title="Cost Insights">
      <EntityCostInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/maturity" title="Maturity">
      <EntityMaturitySummaryContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/maturity" title="Maturity">
      <EntityMaturitySummaryContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const resourceEntityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isResourceType('aws-resource')}>
      <AwsComponentPage componentType="aws-resource" />
    </EntitySwitch.Case>
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const awsEnvironmentProviderEntityPage = <AwsEnvironmentProviderPage />;

const awsEnvironmentEntityPage = <AwsEnvironmentPage />;

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case if={isKind('resource')} children={resourceEntityPage} />
    <EntitySwitch.Case
      if={isKind('awsenvironment')}
      children={awsEnvironmentEntityPage}
    />
    <EntitySwitch.Case
      if={isKind('awsenvironmentprovider')}
      children={awsEnvironmentProviderEntityPage}
    />
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
